import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SimpleInfoCard from "../../../examples/Cards/InfoCards/SimpleInfoCard";

import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LandscapeIcon from '@mui/icons-material/Landscape';
import MKBox from "../../../components/MKBox";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

const features = [
    { icon: <DirectionsTransitIcon />, title: "Skvělá dopravní dostupnost", description: "Přímo nad stanicí metra linky B s rychlým přístupem do centra Prahy." },
    { icon: <WbSunnyIcon />, title: "Sluneční byty", description: "Jižní terasy a balkóny poskytují světlé a prostorné místnosti." },
    { icon: <StorefrontIcon />, title: "Moderní parter", description: "Obchody a restaurace přímo pod bytovými domy pro pohodlí obyvatel." },
    { icon: <LandscapeIcon />, title: "Úchvatné výhledy", description: "Výhledy na parky a zelené oblasti Prahy." },
];

function Features() {
    const [sectionRef, isVisible] = useIntersectionObserver({timeout: 100});

    return (
      <MKBox
        component="section"
        py={8}
        sx={{background: "linear-gradient(to right, #f8f9fa, #e9ecef)" }}
        mt={-2}
        mx={-2}
      >
          <Container>
              <Grid container spacing={3}>
                  {features.map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      md={3}
                      ref={sectionRef}
                      key={index}
                      sx={{
                          opacity: isVisible ? 1 : 0,
                          transform: isVisible ? "translateY(0)" : "translateY(50px)",
                          transition: `opacity 0.6s ease-out ${index * 0.2}s, transform 0.6s ease-out ${index * 0.2}s`,
                      }}
                    >
                        <SimpleInfoCard
                          icon={item.icon}
                          title={item.title}
                          description={item.description}
                          direction="center"
                          bgColor="primary"
                          color="secondary"
                          sx={{
                              // backgroundColor: "#FFD700",
                              "&:hover": {
                                  transform: "scale(1.05)",
                                  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)"
                              }
                          }}
                        />
                    </Grid>
                  ))}
              </Grid>
          </Container>
      </MKBox>
    );
}

export default Features;
