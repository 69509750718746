import React from "react";
import Grid from "@mui/material/Grid";
import team1 from "../../assets/images/rental/1/external-4_resized.jpg";
import team2 from "../../assets/images/rental/1/external-5_resized.jpg";
import team3 from "../../assets/images/rental/1/external-2_resized.jpg";
import team4 from "../../assets/images/rental/1/external-1_resized.jpg";
import MKBox from "../../components/MKBox";
import HorizontalTeamCard from "../../examples/Cards/TeamCards/HorizontalTeamCard";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

function TeamCard({ image, name, position, description }) {
  const [elementRef, isVisible] = useIntersectionObserver({
    threshold: 0.2,
  });

  return (
    <MKBox
      ref={elementRef}
      mb={1}
      sx={{
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(50px)",
        transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
      }}
    >
      <HorizontalTeamCard
        image={image}
        name={name}
        position={{ color: position.color, label: position.label }}
        description={description}
      />
    </MKBox>
  );
}

function Team() {
  return (
    <MKBox component="section" position="relative" py={2} pt={12} sx={{margin: "0 20px"}}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <TeamCard
              image={team1}
              name="Nastěhování"
              position={{ color: "info", label: "" }}
              description="Kolaudace projektu je plánována na konec roku 2024, přičemž předání bytů novým majitelům proběhne v druhém čtvrtletí roku 2025."
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TeamCard
              image={team4}
              name="Technické standardy"
              position={{ color: "info", label: "" }}
              description="Projekt Paprsek Stodůlky nabízí moderní byty s chytrým systémem, podlahovým vytápěním a energeticky úspornými prvky."
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TeamCard
              image={team2}
              name="Financování"
              position={{ color: "info", label: "" }}
              description="Developer nabízí financování s dotovanou hypotékou a garantovanou úrokovou sazbou 2,99 % p.a.,
              což zajišťuje stabilitu a předvídatelnost vašich splátek."
            />
          </Grid>
          <Grid item xs={12} lg={6} >
            <TeamCard
              image={team3}
              name="Žádné poplatky"
              position={{ color: "info", label: "" }}
              description="Při koupi bytu v projektu Paprsek získáte kompletní realitní servis bez dalších nákladů. Všechny naše služby jsou pro vás zdarma."
            />
          </Grid>
        </Grid>
    </MKBox>
  );
}

export default Team;
