import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import DefaultNavbarMobile from "./DefaultNavbarMobile";

function CustomNavbar({ brand, routes, action, onLinkClick }) {
  const theme = useTheme();
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const toggleMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    const updateView = () => {
      setMobileView(window.innerWidth < 1200);
      setMobileNavbar(false);
    };

    window.addEventListener("resize", updateView);
    updateView();
    return () => window.removeEventListener("resize", updateView);
  }, []);

  const handleLinkClick = (route) => (e) => {
    e.preventDefault();
    if (onLinkClick) {
      onLinkClick(route);
    }
  };

  const renderNavbarItems = () =>
    routes.map(({ name, route }) => (
      <MKTypography
        key={name}
        component="a"
        href={route}
        onClick={handleLinkClick(route)}
        variant="button"
        textTransform="capitalize"
        py={1}
        px={3}
        fontSize="1.1rem"
        sx={{
          cursor: "pointer",
          color: theme.palette.white.main,
          position: "relative",
          transition: "color 300ms ease",
          "&::after": {
            content: '""',
            position: "absolute",
            width: "0",
            height: "2px",
            bottom: "-2px",
            left: "0",
            backgroundColor: theme.palette.primary.main,
            transition: "width 300ms ease",
          },
          "&:hover": {
            color: theme.palette.primary.main,
            "&::after": {
              width: "100%",
            },
          },
        }}
      >
        {name}
      </MKTypography>
    ));

  return (
    <MKBox
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        width: "100%",
        backgroundColor: theme.palette.secondary.main,
        py: 2,
        px: 4,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container maxWidth="lg">
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox component="a" href="#home" sx={{ textDecoration: "none", color: theme.palette.white.main }}>
            <MKTypography
              variant="h4"
              color="inherit"
              sx={{
                transition: "all 300ms ease-in-out",
                "&:hover": {
                  color: theme.palette.primary.main,
                  textShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  transform: "scale(1.05)",
                },
              }}
            >
              {brand}
            </MKTypography>
          </MKBox>

          <MKBox display={{ xs: "none", md: "flex" }} alignItems="center">
            {renderNavbarItems()}
          </MKBox>

          <MKButton component="a" href={action.route} variant="contained">
            {action.label}
          </MKButton>

          <MKBox display={{ xs: "block", md: "none" }} onClick={toggleMobileNavbar}>
            {mobileNavbar ? <CloseIcon sx={{ color: theme.palette.white.main }} /> : <MenuIcon sx={{ color: theme.palette.white.main }} />}
          </MKBox>
        </MKBox>
      </Container>

      {mobileView && mobileNavbar && <DefaultNavbarMobile routes={routes} open={mobileNavbar} />}
    </MKBox>
  );
}

CustomNavbar.defaultProps = {
  brand: "Paprsek",
  action: null,
};

CustomNavbar.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onLinkClick: PropTypes.func.isRequired,
};

export default CustomNavbar;