import React, { useEffect, useRef, useState } from "react";

function HeaderBreak() {
  const [isSvgVisible, setIsSvgVisible] = useState(false);
  const svgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsSvgVisible(true);
          } else {
            setIsSvgVisible(false);
          }
        });
      },
      { threshold: 1 }
    );
    const element = svgRef.current;

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <div
      ref={svgRef}
      style={{
        position: "relative",
        overflow: "hidden",
        lineHeight: 0,
        height: "100px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
        style={{
          display: "block",
          width: "100%",
          height: "100px",
        }}
      >
        <path
          fill="#446B5B"
          d="M0,192L60,208C120,224,240,256,360,234.7C480,213,600,139,720,138.7C840,139,960,213,1080,240C1200,267,1320,245,1380,234.7L1440,224V0H0Z"
          style={{
            transform: isSvgVisible ? "translateX(0)" : "translateX(-100%)",
            transition: "transform 1.5s ease-out",
          }}
        ></path>
        <path
          fill="#446B5B"
          d="M0,192L60,208C120,224,240,256,360,234.7C480,213,600,139,720,138.7C840,139,960,213,1080,240C1200,267,1320,245,1380,234.7L1440,224V0H0Z"
          style={{
            transform: isSvgVisible ? "translateX(0)" : "translateX(100%)",
            transition: "transform 1.5s ease-out",
          }}
        ></path>
      </svg>
    </div>
  )
}

export default HeaderBreak;