import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver"; // Assuming you have this hook available

import post1 from "../../../assets/images/rental/1/2.jpg";
import post2 from "../../../assets/images/rental/1/3.jpg";
import post3 from "../../../assets/images/rental/1/4.jpg";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import SimpleBackgroundCard from "../../../examples/Cards/BackgroundCards/SimpleBackgroundCard";
import OneRoomPDF from '../../../assets/pdf/1kk.pdf';
import TwoRoomPDF from '../../../assets/pdf/2kk.pdf';
import ThreeRoomPDF from '../../../assets/pdf/3-4kk.pdf';

const cardConfigs = [
  {
    image: post1,
    title: "1+kk Byt",
    description: { text: "od", coloredText: "29,9 m²" },
    additionalDescription: { text: "ceny od", coloredText: "5 058 139 Kč" },
    pdf: OneRoomPDF,
  },
  {
    image: post2,
    title: "2+kk Byt",
    description: { text: "od", coloredText: "46,8 m²" },
    additionalDescription: { text: "ceny od", coloredText: "7 406 231 Kč" },
    pdf: TwoRoomPDF,
  },
  {
    image: post3,
    title: "3+kk Byt",
    description: { text: "od", coloredText: "76 m²" },
    additionalDescription: { text: "ceny od", coloredText: "11 702 270 Kč" },
    pdf: ThreeRoomPDF,
  },
];

function About() {
  const [card1Ref, isCard1Visible] = useIntersectionObserver({ threshold: 0.5 });
  const [showCard1, setShowCard1] = useState(false);
  const [showCard2, setShowCard2] = useState(false);
  const [showCard3, setShowCard3] = useState(false);

  useEffect(() => {
    if (isCard1Visible) {
      setTimeout(() => setShowCard1(true), 0);
      setTimeout(() => setShowCard2(true), 500);
      setTimeout(() => setShowCard3(true), 1000);
    }
  }, [isCard1Visible]);

  return (
    <MKBox component="section" pb={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          sx={{ margin: "0 auto" }}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <MKTypography variant="h3" mt={3} mb={1}>
            Vyberte si svůj ideální byt
          </MKTypography>
          <MKTypography variant="body2" color="text" mb={2}>
            Paprsek nabízí širokou škálu bytů v srdci Prahy. Získejte svůj nový domov v jedné z
            nejkrásnějších oblastí města.
          </MKTypography>
        </Grid>

        <Grid container spacing={3} mt={3}>
          {cardConfigs.map((config, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              lg={4}
              key={config.title}
              ref={index === 0 ? card1Ref : null}  // Only card 1 needs the observer ref
              sx={{
                opacity: (index === 0 && showCard1) ||
                (index === 1 && showCard2) ||
                (index === 2 && showCard3) ? 1 : 0,
                transform: (index === 0 && showCard1) ||
                (index === 1 && showCard2) ||
                (index === 2 && showCard3) ? "translateY(0)" : "translateY(20px)",
                transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
              }}
            >
              <SimpleBackgroundCard
                image={config.image}
                title={config.title}
                description={config.description}
                additionalDescription={config.additionalDescription}
                pdf={config.pdf}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <div id="pricing"></div>
    </MKBox>
  );
}

export default About;
