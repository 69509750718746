import { useEffect, useRef } from "react";
import Typed from "typed.js";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton";
import bgImage from "../../assets/images/bg.jpg";
import mobileBgImage from "../../assets/images/mobile-bg.jpg";
import { useNavigate } from "react-router-dom";

function Header() {
  const typedJSRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["sofistikovaný styl.", "elegantní prostory.", "pohodlí na dosah.", "domov vašich snů."],
      typeSpeed: 100,
      backSpeed: 90,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <MKBox component="header" position="relative" height="100vh">
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100%"
        sx={{
          backgroundImage: {
            xs: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${mobileBgImage})`,
            md: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${bgImage})`,
          },
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent={{ xs: "space-between", lg: "center" }}
            alignItems="center"
            textAlign="center"
            mx="auto"
            sx={() => ({
              minHeight: "60vh",
              padding: { xs: "1rem 0", lg: "0" },
              justifyContent: { xs: "space-between", md: "center" },
            })}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["4xl"],
                },
                [breakpoints.up("md")]: {
                  fontSize: size["6xl"],
                },
              })}
              mb={2}
            >
              Paprsek to je...
            </MKTypography>
            <MKTypography
              variant="h1"
              color="white"
              mb={2}
              sx={{ display: "block", minHeight: "50px" }}
            >
              <span ref={typedJSRef} />
            </MKTypography>
            <div>
              <MKTypography
                variant="body1"
                color="white"
                mt={1}
                mb={6}
                px={{ xs: 3, lg: 6 }}
                sx={{
                  lineHeight: 1.6,
                }}
              >
                Vítejte v rezidenci Paprsek – místě, kde moderní design splývá s pohodlím a tvoří prostor, do kterého se budete chtít vracet znovu a znovu.
              </MKTypography>
              <MKButton
                color="primary"
                variant="contained"
                sx={{ borderRadius: '10px', boxShadow: 3 }}
                onClick={() => {
                  const section = document.querySelector("#contact");
                  if (section) {
                    section.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                  Kontaktujte nás
              </MKButton>
            </div>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Header;
