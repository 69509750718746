import { useEffect, useState, useRef } from "react";

function useIntersectionObserver(options) {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setTimeout(() => {
          setIsVisible(true);
        }, options?.timeout ?? 300)
        observer.unobserve(entry.target);
      }
    }, options);
    const element = elementRef.current;

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [elementRef, options]);

  return [elementRef, isVisible];
}

export default useIntersectionObserver;
