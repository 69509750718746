import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import Icon from "@mui/material/Icon";
import { useTheme } from "@mui/material/styles";

function DefaultNavbarMobile({ routes, open }) {
  const theme = useTheme();

  const renderNavbarItems = routes.map(({ name, icon, href, route }) => (
    <MKBox key={name} px={2} py={2} display="flex" alignItems="center" justifyContent="center" width="100%">
      <MKTypography
        component="a"
        href={route || href || ""}
        target={href ? "_blank" : ""}
        rel={href ? "noreferrer" : ""}
        variant="h5"
        textTransform="capitalize"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontWeight="bold"
        width="100%"
        sx={{
          cursor: "pointer",
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.primary.light,
          borderRadius: "8px",
          transition: "all 300ms linear",
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main,
            transform: "scale(1.05)",
          },
          padding: "12px",
        }}

      >
        {icon && (
          <Icon sx={{ verticalAlign: "middle", mr: 1 }}>{icon}</Icon>
        )}
        {name}
      </MKTypography>
    </MKBox>
  ));

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <MKBox width="100%" my={2}>
        {renderNavbarItems}
      </MKBox>
    </Collapse>
  );
}

DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.node,
    href: PropTypes.string,
    route: PropTypes.string,
  })).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
