import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "../../../components/MKBox";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";
import Map from "../../../assets/images/location.jpg";

import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import TrainIcon from "@mui/icons-material/Train";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

function Location() {
  const [mapRef, isMapVisible] = useIntersectionObserver({ threshold: 0.1, timeout: 500 });
  const [cardHover, setCardHover] = useState(null);

  const handleCardHover = (index) => {
    setCardHover(index);
  };

  const handleCardLeave = () => {
    setCardHover(null);
  };

  return (
    <>
      <MKBox component="section" py={3} sx={{ backgroundColor: "#fff" }}>
        <Container sx={{ maxWidth: "100%", minWidth: "100%" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
              <MKBox
                ref={mapRef}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: isMapVisible ? 1 : 0,
                  transform: isMapVisible ? "scale(1)" : "scale(0.9)",
                  transition: "opacity 1.8s ease-out, transform 1.8s ease-out",
                  "&:hover": {
                    transform: "scale(1.05)"
                  }
                }}
              >
                <MKBox
                  sx={{
                    width: "100%",
                    height: "auto",
                    overflow: "hidden"
                  }}
                >
                  <MKBox
                    component="img"
                    src={Map}
                    alt="Location Map"
                    sx={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover"
                    }}
                  />
                </MKBox>
              </MKBox>
            </Grid>
            <Grid item xs={12} lg={6} container>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <MKBox
                    mb={5}
                    onMouseEnter={() => handleCardHover(0)}
                    onMouseLeave={handleCardLeave}
                    sx={{
                      transform: cardHover === 0 ? "scale(1.05)" : "scale(1)",
                      transition: "transform 0.3s ease"
                    }}
                  >
                    <DefaultInfoCard
                      icon={<FlightTakeoffIcon />}
                      title="Letiště Václava Havla"
                      description="Hlavní mezinárodní letiště v Praze, situované západně od města."
                    />
                  </MKBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MKBox
                    mb={5}
                    onMouseEnter={() => handleCardHover(1)}
                    onMouseLeave={handleCardLeave}
                    sx={{
                      transform: cardHover === 1 ? "scale(1.05)" : "scale(1)",
                      transition: "transform 0.3s ease"
                    }}
                  >
                    <DefaultInfoCard
                      icon={<TrainIcon />}
                      title="Metro B Stodůlky"
                      description="Stanice metra na lince B, odkud se pohodlně dostanete do oblasti Paprsek i do dalších částí města."
                    />
                  </MKBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MKBox
                    mb={5}
                    onMouseEnter={() => handleCardHover(2)}
                    onMouseLeave={handleCardLeave}
                    sx={{
                      transform: cardHover === 2 ? "scale(1.05)" : "scale(1)",
                      transition: "transform 0.3s ease"
                    }}
                  >
                    <DefaultInfoCard
                      icon={<LocationCityIcon />}
                      title="Karlovo Náměstí"
                      description="Jedno z nejvýznamnějších náměstí v samém srdci Prahy, bohaté na historické památky a kulturu."
                    />
                  </MKBox>
                </Grid>

                <Grid item xs={12} md={6}>
                  <MKBox
                    mb={5}
                    onMouseEnter={() => handleCardHover(3)}
                    onMouseLeave={handleCardLeave}
                    sx={{
                      transform: cardHover === 3 ? "scale(1.05)" : "scale(1)",
                      transition: "transform 0.3s ease"
                    }}
                  >
                    <DefaultInfoCard
                      icon={<MapIcon />}
                      title="Oblast Paprsek"
                      description="Moderní a rychle se rozvíjející oblast na západ od Prahy, s výbornou dopravní dostupností díky blízkosti metra a hlavních dopravních uzlů."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Container>
        <div id="location"></div>
      </MKBox>
    </>
);
}

export default Location;
