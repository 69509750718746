import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import image from "../../../assets/images/rental/1/external-3.jpg";

function InfoCard() {
  const [elementRef, isVisible] = useIntersectionObserver({ threshold: 0.2, timeout: 100 });

  return (
    <Container sx={{ maxWidth: "100% !important", padding: "0 !important" }}>
      <Grid container item xs={10} sx={{ maxWidth: "100% !important" }}>
        <MKBox
          ref={elementRef}
          variant="gradient"
          bgColor="dark"
          width="100%"
          py={6}
          sx={{
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? "translateY(0)" : "translateY(50px)",
            transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
              <MKBox
                component="img"
                src={image}
                alt="Paprsek property"
                borderRadius="md"
                maxWidth="300px"
                width="100%"
                position="relative"
                mt={-12}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              xl={4}
              sx={{
                position: "relative",
                px: { xs: 6, md: 2 },
                mt: { xs: 3, md: 0 },
                my: { xs: 0, md: "auto" },
                color: ({ palette: { white } }) => white.main,
              }}
            >
              <FormatQuoteIcon fontSize="large" />
              <MKTypography
                variant="body2"
                color="white"
                fontWeight="light"
                mb={2}
                sx={{ fontSize: "1.125rem" }}
              >
                Projekt Paprsek je moderní rezidenční projekt v prestižní lokalitě Stodůlky, který nabízí vysoký standard bydlení a široký výběr bytů, od menších jednotek po prostorné rodinné byty s terasami a balkóny orientovanými na jih.
                Projekt je vybaven komerčními prostory v přízemí, jako jsou obchody a restaurace, které zajišťují pohodlí obyvatel. Stodůlky spojují pohodlí městského života a blízkost přírody. Stanice metra se nachází pouhých 50 metrů od domu a okolní parky a rekreační oblasti poskytují možnosti aktivního trávení volného času.
              </MKTypography>
            </Grid>
            <Grid item xs={1} />
            <Grid
              item
              xs={12}
              xl={2}
              sx={{ px: { xs: 6, xl: 0 }, my: { xs: 0, xl: "auto" }, lineHeight: 1 }}
            >
              <MKTypography variant="h3" mt={{ xs: 6, xl: 0 }} mb={1} sx={{color: "#FFD700"}}>
                53 bytů k nastěhování
              </MKTypography>
              <MKTypography component="p" variant="button" color="white" opacity={0.8} mb={2} sx={{color: "#FFD700"}}>
                Připojte se ke spokojeným majitelům bytů v projektu Paprsek.
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>
      </Grid>
    </Container>
  );
}

export default InfoCard;
