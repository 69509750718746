import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import Box from "@mui/material/Box";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import remax_logo from '../../../assets/images/rental/remax_lekvi_logo.png';
import { useTheme } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';

function Footer() {
  const date = new Date().getFullYear();
  const theme = useTheme();

  return (
    <MKBox component="footer" py={4} sx={{ backgroundColor: theme.palette.secondary.main }} mx={-2}>
      <div id="contact"></div>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
            <Box
              component="img"
              src={remax_logo}
              alt="RE/MAX Brand"
              sx={{ width: '100%', maxWidth: '120px', background: "transparent", mb: 2, mx: { xs: 'auto', md: 0 } }}
            />
            <MKTypography variant="h6" color="white" mb={1}>
              RE/MAX Lekvi
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
              Oficiální prodejce projektu
            </MKTypography>
          </Grid>

          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Pitterova 2855/13, 130 00 Praha 3
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              <AccessTimeIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Otevírací doba: Po - Pá: 9:00 - 18:00
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              <a href="tel:+420702160835" style={{ color: 'white', textDecoration: 'none' }}>
                +420 702 160 835
              </a>
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              <a href="mailto:lekvi@re-max.cz" style={{ color: 'white', textDecoration: 'none' }}>
                lekvi@re-max.cz
              </a>
            </MKTypography>
          </Grid>

          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
            <IconButton href="https://www.facebook.com/remax.lekvi"
                        sx={{ color: theme.palette.primary.main, mx: -1, padding: 0 }}>
              <FacebookIcon fontSize="large" />
            </IconButton>
            <IconButton href="https://www.instagram.com/remax.lekvi"
                        sx={{ color: theme.palette.primary.main, mx: 2, padding: 0 }}>
              <InstagramIcon fontSize="large" />
            </IconButton>
            <IconButton href="https://www.linkedin.com/company/remax-lekvi/"
                        sx={{ color: theme.palette.primary.main, padding: 0, mx: -1 }}>
              <LinkedInIcon fontSize="large" />
            </IconButton>

            <MKTypography variant="body2" color="white" opacity={0.8} mb={1} mt={1}>
              IČ: 24786501
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              DIČ: CZ24786501
            </MKTypography>
            <MKTypography variant="body2" color="white" mb={1}>
              &copy; {date} RE/MAX Lekvi. All rights reserved.
            </MKTypography>
          </Grid>
        </Grid>
        <MKBox textAlign="center" mt={4}>
          <MKTypography variant="body2" color="white" opacity={0.8}>
            Informace o ochraně údajů naleznete v našich{' '}
            <a
              href="https://www.remax-lekvi.cz/informace-o-zpracovani-osobnich-udaju/"
              style={{ color: theme.palette.primary.main, textDecoration: 'underline' }}
            >
              Zásadách ochrany osobních údajů
            </a>.
          </MKTypography>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Footer;
