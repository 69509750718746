import React from "react";
import Slider from "react-slick";
import MKBox from "../../../components/MKBox";
import { IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from '../../../assets/images/rental/1/room-2.jpeg';
import image2 from '../../../assets/images/rental/1/room-1.jpeg';
import image3 from '../../../assets/images/rental/1/room-3.jpeg';
import image4 from '../../../assets/images/rental/1/room-4.jpeg';
import image5 from '../../../assets/images/rental/1/room-5.jpeg';
import image6 from '../../../assets/images/rental/1/room-6.jpeg';
import image7 from '../../../assets/images/rental/1/room-7.jpeg';
// import image2 from '../../../assets/images/examples/gallery-2.jpg';
// import image3 from '../../../assets/images/examples/gallery-3.jpg';
// import image4 from '../../../assets/images/examples/gallery-4.jpg';
// import image5 from '../../../assets/images/examples/gallery-5.jpg';
// import image6 from '../../../assets/images/examples/gallery-6.jpg';
// import image7 from '../../../assets/images/examples/gallery-7.jpg';

const images = [
  { src: image1, alt: 'Image 1' },
  { src: image2, alt: 'Image 2' },
  { src: image3, alt: 'Image 3' },
  { src: image4, alt: 'Image 4' },
  { src: image5, alt: 'Image 5' },
  { src: image6, alt: 'Image 6' },
  { src: image7, alt: 'Image 7' },
];

const Gallery = () => {
  const settings = {
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: '40px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: '20px',
        }
      }
    ],
    dots: true,
    arrows: true,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <MKBox py={10} sx={{ backgroundColor: "#f5f5f5" }}>
      <Slider {...settings} style={{ overflow: "visible" }}>
        {images.map((image, index) => (
          <MKBox
            component="div"
            px={2}
            key={index}
            sx={{
              position: "relative",
              transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
              aspectRatio: "16/9",
              zIndex: 1,
              "&:hover": {
                transform: "scale(1.02)"
              },
              outline: "none"
            }}
          >
            <img
              src={image.src}
              alt={image.alt}
              style={{
                width: "100%",
                borderRadius: "8px",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                outline: "none !important"
              }}
            />
          </MKBox>
        ))}
      </Slider>
      <div id="gallery"></div>
    </MKBox>
);
};

const NextArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '50%',
      right: '5px',
      transform: 'translateY(-50%)',
      zIndex: 2,
      backgroundColor: '#365745',
      color: '#fff',
      "&:hover": {
        backgroundColor: '#FFD700',
      }
    }}
  >
    <ChevronRightIcon fontSize="large" />
  </IconButton>
);

const PrevArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '50%',
      left: '5px',
      transform: 'translateY(-50%)',
      zIndex: 2,
      backgroundColor: '#365745',
      color: '#fff',
      "&:hover": {
        backgroundColor: '#FFD700',
      }
    }}
  >
    <ChevronLeftIcon fontSize="large" />
  </IconButton>
);

export default Gallery;
