import { useEffect } from "react";
import Rental from "./Rental";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./assets/theme";
import { BrowserRouter } from "react-router-dom";
import smoothscroll from 'smoothscroll-polyfill';

function App() {
  useEffect(() => {
    const handleAnchorClicks = (e) => {
      const target = e.target.closest('a[href^="#"]');
      if (target) {
        e.preventDefault();
        const targetId = target.getAttribute('href').slice(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth',
          });
        }
      }
    };

    document.addEventListener('click', handleAnchorClicks);

    return () => {
      document.removeEventListener('click', handleAnchorClicks);
    };
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();
    if (window.ResizeObserver) {
      const resizeObserverErrorHandler = (e) => {
        if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          e.stopImmediatePropagation();
        }
      };
      window.addEventListener('error', resizeObserverErrorHandler);

      return () => {
        window.removeEventListener('error', resizeObserverErrorHandler);
      };
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Rental />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
