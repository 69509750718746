import React from "react";
import { useMediaQuery } from "@mui/material";
import Card from "@mui/material/Card";
import Navbar from "../examples/Navbars/DefaultNavbar";
import MKBox from "../components/MKBox";
import theme from "../assets/theme";
import Header from "./components";
import About from "./pages/About";
import Team from "./pages/team";
import Contacts from "./pages/Contacts";
import Location from "./pages/Location";
import HeaderBreak from "./components/HeaderBreak";
import InfoCard from "./pages/InfoCard";
import Footer from "./pages/Footer";
import Gallery from "./pages/Gallery";
import Features from "./components/Features";


const routes = [
  { name: "O projektu", route: "#header" },
  { name: "Ceník", route: "#pricing" },
  { name: "Lokalita", route: "#location" },
  { name: "Galerie", route: "#gallery" },
  { name: "Kontakt", route: "#contact" },
];

function Rental() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Navbar
        routes={routes}
        action={{
          type: "external",
          route: "#contact",
          label: "+420 702 160 835",
          color: "secondary",
        }}
        center
        sticky
      />
      <div id="header">
        <Header />
      </div>
      <Card
        sx={{
          p: 0,
          mx: { xs: 0, sm: 2, lg: 3 },
          mt: -8,
          mb: isMobile ? 0 : 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
          ml: isMobile ? 0 : 2,
          mr: isMobile ? 0 : 2,
          borderRadius: isMobile ? 0 : "16px"
        }}
      >
        <div id="header">
          <Features />
          <InfoCard />
          <HeaderBreak />
          <Team />
        </div>
        <HeaderBreak />
          <About />
          <HeaderBreak />
          <Location />
          <Gallery />
          <Contacts />
      </Card>
      <MKBox width="100%" mt={isMobile ? 0 : 2}>
        <Footer />
      </MKBox>
    </>
  );
}

export default Rental;
