import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import BackgroundImage from "../../../assets/images/contact-us-bg.png";
import HeaderBreak from "../../components/HeaderBreak";
import { useTheme } from "@mui/material/styles";
import * as emailjs from "@emailjs/browser";

function Contacts() {
  const theme = useTheme();

  // Initialize EmailJS
  useEffect(() => {
    emailjs.init("pCJb3sg7eZwefJyg0");
  }, []);

  // Form state management
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [buttonText, setButtonText] = useState('Odeslat zprávu');
  const [buttonColor, setButtonColor] = useState('primary');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const validate = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = "Jméno je povinné";
    if (!formData.phone.trim()) {
      newErrors.phone = "Telefonní číslo je povinné";
    } else if (!/^\+?[0-9\s-]+$/.test(formData.phone)) {
      newErrors.phone = "Telefonní číslo je neplatné";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email je povinný";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email má nesprávný formát";
    }
    if (!formData.message.trim()) newErrors.message = "Zpráva je povinná";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    setIsButtonDisabled(!validate());
  }, [formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsButtonDisabled(true);
    setButtonText('Odesílání...');
    setButtonColor('secondary');

    const serviceId = "service_q6a9fa8";
    const templateId = "template_wp8qsvf";
    try {
      await emailjs.send(serviceId, templateId, {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        message: formData.message,
      });
      setButtonText('Odesláno');
      setButtonColor('success');

      setFormData({
        name: '',
        phone: '',
        email: '',
        message: '',
      });
      setErrors({});
    } catch (error) {
      console.log(error);
      setButtonText('Chyba!');
      setButtonColor('error');
    }
  };

  return (
    <MKBox
      component="section"
      sx={{
        position: "relative",
        overflow: "hidden",
        background: `linear-gradient(135deg, ${theme.palette.background.default} 4%, ${theme.palette.background.default} 100%)`,
        pb: 4
      }}
    >
      <HeaderBreak />
      <MKBox
        component="div"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "100%",
          zIndex: 0,
          backgroundImage: `url(${BackgroundImage})`,
          opacity: 0.25,
          backgroundSize: "cover",
          backgroundPosition: "center",
          filter: "blur(2px)"
        }}
      />
      <Container sx={{ position: "relative", zIndex: 1 }}>
        <Grid container item justifyContent="center" xs={12} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={2} sx={{
            color: theme.palette.info.main,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: "36px"
          }}>
            Napište nám!
          </MKTypography>
        </Grid>

        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <MKBox
            width="100%"
            component="form"
            method="post"
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{
              padding: 4,
              borderRadius: theme.shape.borderRadius,
              backgroundColor: "rgba(255, 255, 255, 0.85)",
              boxShadow: theme.shadows[6],
              transition: "transform 0.3s ease-in-out",
              "&:hover": { transform: "scale(1.02)" },
              backdropFilter: "blur(10px)"
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <MKInput
                  variant="standard"
                  label="Jméno"
                  fullWidth
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  sx={{
                    input: { color: theme.palette.text.primary, fontSize: theme.typography.fontSize },
                    label: { color: theme.palette.text.secondary, fontSize: "14px" }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MKInput
                  variant="standard"
                  type="tel"
                  label="Telefonní číslo"
                  fullWidth
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  sx={{
                    input: { color: theme.palette.text.primary, fontSize: theme.typography.fontSize },
                    label: { color: theme.palette.text.secondary, fontSize: "14px" }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  variant="standard"
                  type="email"
                  label="Email"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  sx={{
                    input: { color: theme.palette.text.primary, fontSize: theme.typography.fontSize },
                    label: { color: theme.palette.text.secondary, fontSize: "14px" }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  variant="standard"
                  label="Vaše zpráva"
                  multiline
                  fullWidth
                  rows={6}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  error={!!errors.message}
                  helperText={errors.message}
                  sx={{
                    input: { color: theme.palette.text.primary, fontSize: theme.typography.fontSize },
                    label: { color: theme.palette.text.secondary, fontSize: "14px" }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={12} my={3}>
              <MKButton
                type="submit"
                variant="gradient"
                color={buttonColor}
                fullWidth
                disabled={isButtonDisabled}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  borderRadius: theme.shape.borderRadius * 2,
                  textTransform: "uppercase",
                  fontWeight: theme.typography.fontWeightBold,
                  padding: "14px 32px",
                  fontSize: "16px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                    transform: "scale(1.05)"
                  }
                }}
              >
                {buttonText}
              </MKButton>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
);
}

export default Contacts;
