import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";
import { useTheme } from "@mui/material/styles";
import DownloadIcon from '@mui/icons-material/Download';

function SimpleBackgroundCard({ image, title, description, additionalDescription, pdf }) {
  const theme = useTheme();

  const handleDownload = () => {
    window.open(pdf, '_blank');
  };

  return (
    <Card
      onClick={handleDownload}
      sx={({
             functions: { rgba, linearGradient },
             palette: { black },
             borders: { borderRadius },
           }) => ({
        backgroundImage: `${linearGradient(
          rgba(black.main, 0.6),
          rgba(black.main, 0.6)
        )}, url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: borderRadius.xl,
        height: "100%",
        display: "grid",
        cursor: "pointer",
        position: "relative",
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.03)",
          boxShadow: `0 6px 18px ${rgba(black.main, 0.4)}`,
        },
        "&:hover .description-content": {
          opacity: 0,
        },
        "&:hover .download-content": {
          opacity: 1,
        },
      })}
    >
      <MKBox
        pt={32} pb={3} px={3}
        className="description-content"
        sx={{
          transition: "opacity 0.3s ease",
          opacity: 1,
        }}
      >
        <MKTypography
          variant="h2"
          color="white"
          mb={1}
          sx={{ color: theme.palette.primary.main }}
        >
          {title}
        </MKTypography>
        <MKTypography variant="h6" color="white" mb={2}>
          {description.text}{" "}
          <MKTypography
            component="span"
            variant="h4"
            sx={{ color: theme.palette.primary.main, fontWeight: "800" }}
          >
            {description.coloredText}
          </MKTypography>
        </MKTypography>

        <MKTypography variant="h6" color="white" mb={2}>
          {additionalDescription.text}{" "}
          <MKTypography
            component="span"
            variant="h4"
            sx={{ color: theme.palette.primary.main }}
          >
            {additionalDescription.coloredText}
          </MKTypography>
        </MKTypography>
      </MKBox>

      <MKBox
        className="download-content"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          opacity: 0,
          transition: "opacity 0.3s ease",
        }}
      >
        <DownloadIcon
          sx={{
            height: "64px",
            width: "64px",
            color: theme.palette.primary.main,
            animation: "pulseIcon 1.5s infinite",
            "@keyframes pulseIcon": {
              "0%": { transform: "scale(1)" },
              "50%": { transform: "scale(1.1)" },
              "100%": { transform: "scale(1)" },
            },
          }}
        />
        <MKTypography
          variant="h6"
          color="white"
          mt={1}
          sx={{
            color: theme.palette.primary.main,
            fontWeight: "bold",
            textTransform: "uppercase",
            transform: "translateY(20px)",
            opacity: 0,
            transition: "opacity 0.5s ease, transform 0.5s ease",
            "&:hover": {
              opacity: 1,
              transform: "translateY(0)",
            },
          }}
        >
          Stáhnout katalog
        </MKTypography>
      </MKBox>

      <MKBox
        sx={{
          position: "absolute",
          top: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          opacity: 0.8,
          color: theme.palette.secondary.main,
          fontWeight: "bold",
          textTransform: "uppercase",
          zIndex: 1,
        }}
      >
        <MKTypography
          variant="caption"
          sx={{ color: theme.palette.primary.main, fontWeight: "bold", fontSize: "0.7rem" }}
        >
          Klikněte pro stažení katalogu
        </MKTypography>
      </MKBox>
    </Card>
  );
}

SimpleBackgroundCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  additionalDescription: PropTypes.object.isRequired,
  pdf: PropTypes.string.isRequired,
};

export default SimpleBackgroundCard;
